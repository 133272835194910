<template>
    <div class="landing_main_box">        
        <b-navbar toggleable="lg" fixed="top">
            <div class="container">
                <b-navbar-brand href="#header"><a href="/" target="_blank" class="navbar_link">Okaygorod.com</a>
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item href="#OkayGorod">Заработок</b-nav-item>
                        <b-nav-item href="#function_section">Функционал</b-nav-item>
                        <b-nav-item href="#partners">Партнёрство</b-nav-item>
                        <b-nav-item href="#partners_box">Партнёры</b-nav-item>
                        <b-nav-item href="#price_box">Стоимость</b-nav-item>
                        <b-nav-item href="#contact_box">Контакты</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
        <header id="header">
            <div class="container">
                <div class="header_title_box">
                    <h1>Начни или расширь свой рекламный бизнес с помощью Окей Город!</h1>
                    <small>С нами уже более 30 партнёров!</small>
                    <div class="button_block">
                        <a href="#OkayGorod">
                            <button type="button" class="header_button">Подробнее</button>
                        </a>
                        <!-- <a href="#contact_box" class="button_callback_header">
                            <button type="button">Отправить заявку</button>
                        </a> -->
                    </div>
                </div>
                <div class="header_image_box">
                    <img src="/images/landing/phone-min2.png" alt="image" class="img-fluid">
                </div>
            </div>
        </header>
        <section id="OkayGorod">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Окей Город
                    </div>
                    <div class="section_title_small">
                        Городское мобильное приложение и сайт.<br> Стань владельцем и зарабатывай на продаже рекламы.
                    </div>
                </div>
                <div class="link_box">
                    <a href="https://okaygorod.com/komsomolskonamur" target="_blank" rel="nofollow">
                        <img src="/images/icon/okaygorod.png" alt="Окей Город" title="Сайт Окей Город" style="height: auto !important; max-height: 60px;" class="img-fluid icon">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow">
                        <img src="/images/icon/GooglePlay.svg" alt="GooglePlay" title="GooglePlay" class="icon">
                    </a>
                    <a href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow">
                        <img src="/images/icon/AppStore.svg" alt="AppStore" title="AppStore" class="icon">
                    </a>
                    <a href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow">
                        <img src="/images/icon/huawei-appgallery.svg" alt="AppGallery" title="AppGallery" class="icon">
                    </a>
                </div>
                <div class="section_title">
                    <div class="section_title_title">
                        Виды рекламы, которые <br>Вы можете продавать
                    </div>
                </div>
                <slick id="slick" ref="slick" :options="slickEarnings" class="class_slick">
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/glavnayastranitsa.png" alt="image">
                            <div class="earnings_slick_item_title">Главная страница</div>
                        </div>
                    </div>
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/novosti.png" alt="image">
                            <div class="earnings_slick_item_title">Новости</div>
                        </div>
                    </div>
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/Aktsii.png" alt="image">
                            <div class="earnings_slick_item_title">Акции</div>
                        </div>
                    </div>
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/Afisha.png" alt="image">
                            <div class="earnings_slick_item_title">Афиша</div>
                        </div>
                    </div>
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/Spravochnik.png" alt="image">
                            <div class="earnings_slick_item_title">Справочник</div>
                        </div>
                    </div>
                    <div>
                        <div class="earnings_slick_item">
                            <img src="/images/landing/earnings/Organizatsiy.png" alt="image">
                            <div class="earnings_slick_item_title">Организации</div>
                        </div>
                    </div>
                </slick>
                <div class="section_title">
                    <div class="section_title_title">
                        Цены имеют рекомендательный характер
                    </div>
                </div>
                <div class="table_box" style="max-width: 100% !important">
                    <table class="table table-hover table-striped">
                        <tbody>
                            <tr>
                                <td align="left">Классические баннеры на сайте</td>
                                <td align="right">от 1000 рублей / месяц</td>
                            </tr>
                            <tr>
                                <td align="left">Баннеры в мобильных приложениях</td>
                                <td align="right">от 1000 рублей / месяц</td>
                            </tr>
                            <tr>
                                <td align="left">Рекламные позиции на главном экране приложения</td>
                                <td align="right">от 2000 рублей / месяц</td>
                            </tr>
                            <tr>
                                <td align="left">Размещение информации в справочнике предприятий</td>
                                <td align="right">от 2000 рублей / год</td>
                            </tr>
                            <tr>
                                <td align="left">Реклама в акциях и афишах</td>
                                <td align="right">от 1000 рублей / месяц</td>
                            </tr>
                            <tr>
                                <td align="left">Реклама в городской ленте</td>
                                <td align="right">от 1000 рублей / месяц</td>
                            </tr>
                            <tr>
                                <td align="left">Рекламные статьи</td>
                                <td align="right">от 2000 рублей / статья</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="section_title">
                    <div class="section_title_title">
                        Пример результата
                    </div>
                    <div class="section_title_small">
                        Пример приведен по опыту партнёра
                    </div>
                </div>
                <div class="section_title_small text-center">
                    Наш партнёр Антон Проскуряков из города Белореченск с населением 50.000 человек,
                    имея не большие группы Вконтакте, расширил свой рекламный бизнес с помощью Окей Город и в 2020 г. (в кризис) заработал около <span style="font-weight: bold">471.000 рублей</span> чистой прибыли на проекте "Окей Город". А за первый квартал 2021 года уже заработал чистыми <span style="font-weight: bold">200 000 рублей</span>.
                </div> -->
                <a href="#contact_box" class="button_callback">
                    <button type="button">Отправить заявку</button>
                </a>
            </div>
        </section>
        <section id="functional">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Что Вам нужно делать?
                    </div>
                    <div class="section_title_small">
                        • Заполнить свой город информацией.<br>
                        • Привлечь жителей для использования Окей Город.<br>
                        • Привлечь рекламодателей.<br>
                    </div>
                </div>
                <div class="section_title">
                    <div class="section_title_title">
                        Как это сделать?
                    </div>
                    <div class="section_title_small">
                        Рассказываем в нашей закрытой группе для партнёров.
                    </div>
                </div>
                <div class="section_title" id="function_section">
                    <div class="section_title_title">
                        А наш функционал
                    </div>
                    <div class="section_title_small">
                        Позволяет привлечь активную аудиторию
                    </div>
                </div>
                <b-row class="partners_content">
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons news"></div>
                            </div>
                            <div class="functional_section_item_title">Новости</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons calendar"></div>
                            </div>
                            <div class="functional_section_item_title">Афиша</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons shares"></div>
                            </div>
                            <div class="functional_section_item_title">Акции</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons tape"></div>
                            </div>
                            <div class="functional_section_item_title">Городская лента</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons org"></div>
                            </div>
                            <div class="functional_section_item_title">Справочник</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons add"></div>
                            </div>
                            <div class="functional_section_item_title">Объявления</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons star"></div>
                            </div>
                            <div class="functional_section_item_title">Быстрые кнопки</div>
                        </div>
                    </b-col>
                    <b-col cols="6" md="4" lg="3" class="functional_section_box">
                        <div class="functional_section_item">
                            <div class="functional_section_item_img">
                                <div class="functional_icons advertisement"></div>
                            </div>
                            <div class="functional_section_item_title">И многое другое</div>
                        </div>
                    </b-col>
                </b-row>
                <div class="section_title">
                    <div class="section_title_title">
                        Результаты других партнёров
                    </div>
                </div>
                <div class="statistic_box">
                    <b-row>
                        <b-col cols="12" md="6">
                            <div class="statistic_item">
                                <div class="statistic_count">
                                    467 358
                                </div>
                                <div class="statistic_title">Уникальных пользователей за 2022 г.</div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div class="statistic_item">
                                <div class="statistic_count">
                                    2 400 638
                                </div>
                                <div class="statistic_title">Посещений за 2022 г.</div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <a href="#contact_box" class="button_callback">
                    <button type="button">Отправить заявку</button>
                </a>
            </div>
        </section>
        <section id="partners">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Партнёрам
                    </div>
                    <div class="section_title_small">
                        Используя Окей Город Вы получите
                    </div>
                </div>
                <b-row class="partners_content">
                    <b-col cols="12" md="6" lg="3" class="partners_content_item_box">
                        <div class="partners_content_item">
                            <div class="partners_content_img">
                                <div class="landing_icons phone"></div>
                            </div>
                            <div class="parnters_content_title">Городское СМИ</div>
                            <div class="partners_content_desctiption">Окей Город официально зарегистрировано в Роскомнадзоре как СМИ (Средство массовой информации) Номер свидетельства <br>ЭЛ № ФС 77 - 75366
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="partners_content_item_box">
                        <div class="partners_content_item">
                            <div class="partners_content_img">
                                <div class="landing_icons money"></div>
                            </div>
                            <div class="parnters_content_title">Рекламный бизнес</div>
                            <div class="partners_content_desctiption">Возможность зарабатывать на продаже рекламы. Ваши клиенты будут
                                все коммерческие организации, которые ведут бизнес в вашем городе.
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="partners_content_item_box">
                        <div class="partners_content_item">
                            <div class="partners_content_img">
                                <div class="landing_icons user"></div>
                            </div>
                            <div class="parnters_content_title">Права партнёра</div>
                            <div class="partners_content_desctiption">Вы получите эксклюзивное право на приложение по своему городу.
                                Сотрудничаем по принципу «1 город – 1 партнёр», заключаем договор.
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" lg="3" class="partners_content_item_box">
                        <div class="partners_content_item">
                            <div class="partners_content_img">
                                <div class="landing_icons gears"></div>
                            </div>
                            <div class="parnters_content_title">Поддержка</div>
                            <div class="partners_content_desctiption">Мы на себя берем техническое обслуживание приложения, сайта и развитие функционала. Делимся с Вами успешным опытом других партнёров
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <div class="okaygorod_content_box mt-3 mb-3">
                    <img src="/images/RKN.jpg" alt="image" class="img-fluid">
                    <a href="https://rkn.gov.ru/mass-communications/reestr/media/?id=723471" target="_blank"><button type="button" class="rnk_link">Посмотреть свидетельство на сайте РОСКОМНАДЗОР'а</button></a>
                </div>
                <div class="section_title">
                    <div class="section_title_title">
                        Преимущества Окей Город
                    </div>
                    <div class="section_title_small text-left">
                        • Самые конкурентные цены на рынке<br>
                        • Быстрый запуск города (В течении 1 рабочего дня)<br>
                        • Нативные мобильные приложения<br>
                        • Широкие возможности для монетизации<br>
                        • Политика открытости компании<br>
                        • Создание партнёрских отношений с руководителями организаций<br>
                        • Сотрудничество с государственными учреждениями<br>
                        • Централизованная федеральная сеть городских порталов<br>
                    </div>
                </div>
                <a href="#contact_box" class="button_callback">
                    <button type="button">Отправить заявку</button>
                </a>
            </div>
        </section>
        <!-- <section id="earnings_box">
            <div class="container">
            </div>
        </section> -->
        <section id="partners_box">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Успешные партнёры
                    </div>
                    <div class="section_title_small">
                        Некоторые партнёры берут по второму и третьему городу.
                    </div>
                </div>
                <slick id="slick" ref="slick" :options="slickMain" class="class_slick">
                    <div class="slick_item">
                        <b-row class="slick_item_box">
                            <b-col cols="12" md="2" class="slick_item_image_box"><img src="/images/landing/team/vik.png" alt="team" class="img-fluid"></b-col>
                            <b-col cols="12" md="10">
                                <div class="review-comment">
                                    <h3>Антон Проскуряков</h3>
                                    <p>Партнёр по городам Белореченск, Майкоп. Вышел на окупаемость со второго месяца</p>
                                    <a href="https://vk.com/tosha_belora" target="_blank">Написать Антону</a>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="slick_item">
                        <b-row class="slick_item_box">
                            <b-col cols="12" md="2" class="slick_item_image_box"><img src="/images/landing/team/pasha.png" alt="team" class="img-fluid"></b-col>
                            <b-col cols="12" md="10">
                                <div class="review-comment">
                                    <h3>Павел Кострикин</h3>
                                    <p>Партнёр по городам Мичуринск, Тамбов. Вышел на окупаемость с третьего месяца</p>
                                    <a href="https://vk.com/neo__ru" target="_blank">Написать Павлу</a>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="slick_item">
                        <b-row class="slick_item_box">
                            <b-col cols="12" md="2" class="slick_item_image_box"><img src="/images/landing/team/kostya.png" alt="team" class="img-fluid"></b-col>
                            <b-col cols="12" md="10">
                                <div class="review-comment">
                                    <h3>Константин Васильев</h3>
                                    <div style="color: #e64a19">Front-End разработчик сайта Okaygorod.com</div>
                                    <p>Партнёр по городу Комсомольск-на-Амуре. Вышел на окупаемость с третьего месяца</p>
                                    <a href="https://api.whatsapp.com/send?phone=79990884329" target="_blank">Написать Константину</a>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </slick>
                <a href="#contact_box" class="button_callback">
                    <button type="button">Отправить заявку</button>
                </a>
            </div>
        </section>
        <section id="price_box">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Стоимость
                    </div>
                    <div class="section_title_small">
                        Ежемесячное вознаграждение 5 000 рублей
                    </div>
                </div>
                <div class="table_box">
                    <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Население</th>
                                <th>Пакет</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr align="left">
                                <th scope="row">До 300 000</th>
                                <td align="left">300 000 рублей</td>
                            </tr>
                            <tr align="left">
                                <th scope="row">От 300 000 до 1 000 000</th>
                                <td align="left">400 000 рублей</td>
                            </tr>
                            <tr align="left">
                                <th scope="row">От 1 000 000</th>
                                <td align="left">550 000 рублей</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="section_title">
                    <div class="section_title_title">
                        Состав Пакета
                    </div>
                    <div class="section_title_small text-left mt-5 mb-5">
                        • Эксклюзивное Право на открытие Дилерского предприятия.<br>
                        • Онлайн обучение управлению и продвижению приложения.<br>
                        • Доступ к базе знаний и успешного опыта работы других партнеров.<br>
                        • Полностью готовое мобильное приложение с базовым наполнением по Вашему городу
                        для iOS и Android.<br>
                        • Проверенная работающая бизнес-модель.<br>
                        • Макеты рекламных материалов в электронном виде.<br>
                        • Необходимые материалы: бизнес-план, образцы рекламы, документы, скрипты продаж,
                        аналитика и метрика.<br>
                        • Комплект документации: коммерческие предложения, формы договоров, инструкции.<br>
                        • Поддержка и развитие приложения.<br>
                    </div>
                </div>
                <div class="section_title_small">
                    При оплате:<br>
                    3 месяцев - скида 5%<br>
                    6 месяцев - скидка 10%<br>
                    12 месяцев - скидка 20%
                </div>
                <a href="#contact_box" class="button_callback">
                    <button type="button">Отправить заявку</button>
                </a>
            </div>
        </section>
        <section id="contact_box">
            <div class="container">
                <div class="section_title">
                    <div class="section_title_title">
                        Оставьте заявку
                    </div>
                    <div class="section_title_small">
                        Узнайте, свободен ли Ваш город
                    </div>
                    <div class="section_title_small">
                        Телефон: <a href="tel:+79150904999">+7(915)090-49-99 Виктор</a>
                    </div>
                </div>
                <div class="contact_form_box">
                    <div class="form-group">
                        <label for="form_city">Город</label>
                        <input type="text" class="form-control" id="form_city" placeholder="Город" v-model="form.city">
                    </div>
                    <div class="form-group">
                        <label for="form_phone">Телефон</label>
                        <input type="tel" v-mask="'+7(###)###-####'" placeholder="Телефон" class="form-control" id="form_phone" required v-model="form.phone" />
                    </div>
                    <div class="form-group">
                        <label for="form_email">Ваш E-mail</label>
                        <input type="email" class="form-control" id="form_email" placeholder="E-mail" v-model="form.email">
                    </div>
                    <div class="form-group">
                        <label for="form_name">Имя</label>
                        <input type="text" class="form-control" id="form_name" placeholder="Имя" v-model="form.name">
                    </div>
                    <div v-if="preLoad">
                        <button type="button" class="button_send" @click="send">Отправить</button>
                    </div>
                    <div v-else>
                        <PreLoadBox />
                    </div>
                </div>
                <div id="footer">
                    <div class="link_box_title">Скачай бесплатно:</div>
                    <div class="link_box">
                        <a href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow">
                            <img src="/images/icon/GooglePlay.svg" alt="GooglePlay" title="GooglePlay" class="icon">
                        </a>
                        <a href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow">
                            <img src="/images/icon/AppStore.svg" alt="AppStore" title="AppStore" class="icon">
                        </a>
                        <a href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow">
                            <img src="/images/icon/huawei-appgallery.svg" alt="AppGallery" title="AppGallery" class="icon">
                        </a>
                    </div>
                    <div class="footer_social_box">
                        <a href="https://vk.com/ooogoroda.mobi" target="_blank" rel="nofollow">
                            <div class="footer_icon vk"></div>
                        </a>
                    </div>
                    <div class="copyrights" v-html="'Все права защищены © 2018 - ' + $moment().local().format('YYYY')"></div>
                    <div class="ogrn">ИП Маргарян ОГРН 318502700072257</div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slick from 'vue-slick';

export default {
    name: "Partnership",
    components: {
        Slick
    },
    metaInfo() {
        return {
            title: `Франшиза рекламного бизнеса Окей Город мобильное приложение и сайт`,
            meta: [
                {name: 'description', itemprop: 'description', content: 'Стань владельцем городского мобильного приложения и зарабатывай на продаже рекламы.' },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Франшиза рекламного бизнеса Окей Город мобильное приложение и сайт`},
                {property: 'og:description', content: 'Стань владельцем городского мобильного приложения и зарабатывай на продаже рекламы.'},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: 'finish',
            form: {
                city: null,
                phone: null,
                email: null,
                name: null
            },
            slickMain: {
                slidesToShow: 1,
                arrows: false,
                dots: true,
                speed: 500,
                slidesToScroll: 1,
                autoplay: true,
                infinite: true,
                slickGoTo: 1,
                adaptiveHeight: false,
                swipe: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        swipe: true
                    }
                }]

            },
            slickEarnings: {
                slidesToShow: 3,
                arrows: false,
                dots: true,
                speed: 500,
                slidesToScroll: 3,
                autoplay: true,
                infinite: true,
                slickGoTo: 1,
                adaptiveHeight: false,
                swipe: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        swipe: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]

            },
        }
    },

    methods: {
        send() {
            if (!this.form.city || this.form.city.lenght < 3) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Название города должно быть не короче 3 символов',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else if (!this.form.phone || this.form.phone.length !== 15) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Укажите корректный телефон',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else if (!this.form.email || this.form.email.length < 5) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Укажите корректный email',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else if (!this.form.name || this.form.name.length < 3) {
                this.$notify({
                    group: 'error',
                    type: 'error',
                    text: 'Имя должно быть не короче 3 символов',
                    title: 'Ошибка!',
                    duration: 5000,
                });
            } else {
                this.preLoad = null;
                let url = this.$config.api_url + 'Partnership.form';
                this.$http.post(url, this.form).then(() => {
                    this.$notify({
                        group: 'error',
                        type: 'success',
                        text: 'Заявка успешно отправлена',
                        title: 'Поздравляем!',
                        duration: 5000,
                    });
                }).finally(() => {
                    this.preLoad = 'finish';
                });
            }

        },
    },

}
</script>
<style scoped>
* {
    transition: 2s ease all;
    font-family: Helvetica;
}

@media (max-width: 575.98px) {
    .nav-item {
        padding: 10px 0;
    }

    .section_title_title {
        font-size: 20px;

    }

    .section_title_small {
        font-size: 16px;
    }

    .header_title_box h1 {
        font-size: 20px;
        text-align: center !important;
    }

    .header_title_box small {
        font-size: 16px;
        text-align: center !important;
    }

    .header_image_box {
        display: block;
        width: 100%;
    }

    header .container {
        flex-wrap: wrap;

    }

    .button_block button {
        margin-bottom: 20px;
    }

    .statistic_item {
        margin-bottom: 20px;
    }

    .review-comment:after {
        display: none;
    }


    .slick_item_image_box {
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .nav-item {
        padding: 10px 0;
    }

    .section_title_title {
        font-size: 28px;

    }

    .section_title_small {
        font-size: 20px;
    }

    .header_title_box h1 {
        font-size: 24px;
        text-align: center !important;
    }

    .header_title_box small {
        font-size: 18px;
        text-align: center !important;
    }

    .button_block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .button_block button {
        margin-bottom: 20px;
    }

    .statistic_item {
        margin-bottom: 20px;
    }

    .review-comment:after {
        display: none;
    }

    .slick_item_image_box {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .nav-item {
        padding: 10px 0;
    }

    .section_title_title {
        font-size: 28px;

    }

    .section_title_small {
        font-size: 20px;
    }

    .header_title_box h1 {
        font-size: 26px;
    }

    .header_title_box small {
        font-size: 18px;
    }

    #functional .container {
        padding-bottom: 100px;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar {
        max-height: 75px;
    }

    .header_title_box .header_button:hover {
        transform: translateY(-3px);
        background: #fff;
        color: #000;
    }

    .nav-link:hover {
        color: #fff !important;

    }

    .nav-link:hover::before {
        animation: animate .3s linear forwards;
    }


    .partners_content_item:hover {
        transform: translateY(-10px);
    }

    .partners_content_item:hover .landing_icons {
        background-color: #000;

    }

    .partners_content_item:hover .partners_content_img {
        border: 3px solid #fdd835;
        background: #fdd835;

    }


    .functional_section_item:hover {
        transform: translateY(-10px);
        background: #fdd835;
    }

    .functional_section_item:hover .functional_icons {
        background-color: rgba(255, 255, 255, 1);

    }

    .functional_section_item:hover .partners_content_img {
        border: 3px solid #fdd835;
        background: #fdd835;

    }

    .footer_icon:hover {
        background-color: #fdd835;
    }

    .button_callback_header button:hover {
        transform: translateY(-5px);
    }

    .section_title {
        padding-top: 50px;
    }

    .section_title_title {
        font-size: 36px;

    }

    .section_title_small {
        font-size: 22px;
    }

    header {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
        /*background-attachment: fixed;*/
        padding-bottom: 100px;
    }

    #functional {
        /*clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);*/
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    #partners_box {
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    #contact_box {
        clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0 100%);
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .header_title_box h1 {
        font-size: 30px;
    }

    .header_title_box small {
        font-size: 22px;
    }

    #functional .container {
        padding-bottom: 100px;
    }
}

@media (min-width: 1200px) {
    .navbar {
        max-height: 75px;
    }

    .header_title_box .header_button:hover {
        transform: translateY(-3px);
        background: #fff;
        color: #000;
    }

    .nav-link:hover {
        color: #fff !important;

    }

    .nav-link:hover::before {
        animation: animate .3s linear forwards;
    }


    .partners_content_item:hover {
        transform: translateY(-10px);
    }

    .partners_content_item:hover .landing_icons {
        background-color: #000;

    }

    .partners_content_item:hover .partners_content_img {
        border: 3px solid #fdd835;
        background: #fdd835;

    }


    .functional_section_item:hover {
        transform: translateY(-10px);
        background: #fdd835;
    }

    .functional_section_item:hover .functional_icons {
        background-color: rgba(255, 255, 255, 1);

    }

    .functional_section_item:hover .partners_content_img {
        border: 3px solid #fdd835;
        background: #fdd835;

    }

    .footer_icon:hover {
        background-color: #fdd835;
    }

    .button_callback_header button:hover {
        transform: translateY(-5px);
    }

    .section_title {
        padding-top: 50px;
    }

    .section_title_title {
        font-size: 48px;
    }

    .section_title_small {
        font-size: 25px;
    }

    header {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
        /*background-attachment: fixed;*/
        padding-bottom: 100px;
    }

    #functional {
        /*clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);*/
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    #partners_box {
        clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0 90%);
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    #contact_box {
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
        /*background-attachment: fixed;*/
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .header_title_box h1 {
        font-size: 32px;
    }

    .header_title_box small {
        font-size: 24px;
    }

}

.landing_main_box {
    background: #fff !important;
}

.button_block a {
    margin-right: 20px;
}

.section_title {
    margin-bottom: 30px;
}

.section_title_title {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    position: relative;

}

.section_title_title:after {
    content: '';
    height: 4px;
    width: 100px;
    display: block;
    text-align: center;
    margin: 13px auto;
    border-bottom: 5px dashed rgba(33, 150, 243, 0.7);
}

.section_title_small {
    color: #4c4c4c;
    font-weight: 300;
}

section {
    padding: 20px 0;
}

button,
button:focus,
button:active {
    outline: none;
    border: none;
}

.navbar {
    padding: 20px 10px;
    background: #fff;
    border-bottom: 1px solid #fff;
    box-shadow: 0 0 3px #000;
    transition: .3s
}

.navbar * {
    transition: .3s
}

.nav-link {
    position: relative;
    display: block;
    margin: 0 10px;
    padding: 5px 10px;
    color: #aaa;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;
    overflow: hidden;
}

.nav-link:before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: -100%;
    width: 100%;
    height: 4px;
    background: #2196f3;
    transition: 0.3s;
    border-radius: 6px;
}


.navbar_link {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
}

@keyframes animate {
    0% {
        top: calc(50% - 2px);
        left: -100%;
        height: 4px;
        z-index: 1;
    }

    50% {
        top: calc(50% - 2px);
        left: 0;
        height: 4px;
        z-index: 1;
    }

    100% {
        top: 0;
        left: 0;
        height: 100%;
        z-index: -1;
    }
}

@keyframes animate_phone {

    /*0% {
      transform: rotate(0deg);
  }

  25% {
      transform: rotate(3deg);
  }

  50% {
      transform: rotate(0deg);
  }

  75% {
      transform: rotate(-3deg);
  }

  100% {
      transform: rotate(0deg);
  }*/
    0% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1.0);
    }
}

header {
    /*background: linear-gradient(135deg, #0559ab 0%, #3cccd6 100%);*/
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
}

header .container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.header_title_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    padding: 20px 15px;
}

.header_title_box h1 {
    margin-bottom: 20px;
    text-align: left;
    color: white !important;
}

.header_title_box small {
    text-align: left;
    margin-bottom: 20px;
}

.header_title_box .header_button {
    border: 1px solid #fff;
    border-radius: 24px;
    padding: 10px 20px;
    background: transparent;
    color: #fff;
    transition: .2s ease all;
}


.header_image_box {
    padding: 40px;
}

.header_image_box img {
    pointer-events: none;
    animation: animate_phone 5s infinite linear;
}

#OkayGorod {
    position: relative;
}

.okaygorod_content_box img {
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
    max-height: 300px;
}

.rnk_link {
    display: block;
    padding: 20px;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
    color: #fff;
    margin: 20px auto 0;
    border-radius: 80px;
}

#partners {
    position: relative;
}

.partners_content {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.partners_content_item_box {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px;
}

.partners_content_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
    border-radius: 24px;
    padding: 10px;
    transition: .3s;
}


.partners_content_item * {
    color: #fff;
}

.parnters_content_title {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}

.parnters_content_title:after {
    content: '';
    height: 4px;
    width: 100%;
    display: block;
    text-align: center;
    margin: 13px auto;
    border-bottom: 5px dashed rgba(255, 255, 255, 0.7);
}

.partners_content_img {
    transition: .2s;
    border: 3px solid #fff;
    border-radius: 50%;
    padding: 20px;
    background: #fff;

}

.partners_content_desctiption {
    text-align: justify-all;
}

.landing_icons {
    transition: .2s;
    width: 50px;
    height: 50px;
    background-color: #A9B0C0;
    display: block;
}


.gears {
    mask-image: url('/images/landing/icon/gears.svg');

}

.tape {
    mask-image: url('/images/icon/tape.svg');
}

.news {
    mask-image: url('/images/icon/news.svg');
}

.org {
    mask-image: url('/images/icon/org.svg');
}

.shares {
    mask-image: url('/images/icon/supermarket.svg');
}

.add {
    mask-image: url('/images/icon/add.svg');
}


.star {
    mask-image: url('/images/icon/star_menu.svg');
}


.calendar {
    mask-image: url('/images/icon/calendar.svg');
}


.phone {
    mask-image: url('/images/landing/icon/phone.svg');

}

.money {
    mask-image: url('/images/landing/icon/money.svg');

}

.user {
    mask-image: url('/images/landing/icon/user.svg');

}

#functional {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
}

#functional .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#functional .section_title * {
    color: #fff;

}

#functional .section_title {
    margin-top: 50px;
}

.functional_section_box {
    padding: 10px;

}

.functional_section_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    transition: .2s;
}

.functional_section_item_title {
    color: #000;
    font-size: 18px;
}

.functional_section_item_img:after {
    content: '';
    height: 4px;
    width: 100%;
    display: block;
    text-align: center;
    margin: 13px auto;
    border-bottom: 5px dashed rgba(0, 0, 0, 0.7);

}

.functional_icons {
    transition: .2s;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    margin-bottom: 30px;
    position: relative;
}


#partners_box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
}

#partners_box .section_title * {
    color: #fff;

}

.class_slick {
    margin-top: 50px;
}

.slick_item {
    padding: 10px;
}

.review-comment {
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}

.review-comment * {
    text-align: left;
}

.review-comment:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-width: 15px;
    border-right-color: rgba(255, 255, 255, 1);
    z-index: -1;
}

.review-comment h3 {
    padding-bottom: 20px;
}

.slick_item_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick_item_image_box img {
    border-radius: 50%;
    border: 3px solid #fff;
    margin: 0 auto;
}

.earnings_slick_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.functional_section_item {
    height: 100%;

}

.earnings_slick_item_title {
    padding: 20px 0;
    color: #999999;
}

.table_box {
    overflow-x: auto;
    max-width: 500px;
    margin: 0 auto;
}

#contact_box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(25, 131, 187, 1);
    background-image: url('/images/landing/diagmonds.png');
    background-repeat: repeat;
    background-position: bottom center;
}

#contact_box .section_title * {
    color: #fff;

}

.contact_form_box {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 24px;
    padding: 20px 20px;
    max-width: 300px;
    margin: 20px auto;
}

.contact_form_box label {
    font-size: 20px;

}

#footer {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.footer_social_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.footer_social_box a {
    margin-right: 20px;
}

.footer_social_box a:last-child {
    margin-right: 0;
}

.footer_icon {
    height: 50px;
    width: 50px;
    transition: .2s;
    background-color: rgba(255, 255, 255, 1);
    display: block;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
}


.instagram {
    mask-image: url('/images/landing/icon/instagram.svg');

}

.vk {
    mask-image: url('/images/landing/icon/vk.svg');

}

.facebook {
    mask-image: url('/images/landing/icon/facebook.svg');

}

.statistic_box {
    width: 100%;
    margin-bottom: 50px;
}

.statistic_item {
    background: #fff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}

.statistic_count {
    font-weight: bold;
    font-size: 48px;
    color: #e64a19;
}

.statistic_title {
    font-weight: 600;
}

.button_callback_header button {
    background: #fdd835;
    color: #000;
    transition: .3s;
    padding: 10px 35px;
    border-radius: 24px;
    outline: none;
    border: none;
}


.button_callback button {
    background: #fdd835;
    color: #000;
    padding: 15px 35px;
    border-radius: 24px;
    margin: 50px auto;
    animation: calback 2s infinite linear;
}

@keyframes calback {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }
}

.button_send {
    display: block;
    border-radius: 12px;
    background: rgba(25, 131, 187, 1);
    color: #fff;
    padding: 10px 20px;
    width: 100%;
    transition: .3s;
}

.button_send:hover {
    background: #fdd835;
    color: #000;
}

.link_box_title {
    color: #fff;
    font-size: 18px;
}

.link_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.link_box img {
    height: 60px;
    padding: 5px;
}
</style>